<template>
  <skeleton
    v-if="teachers.isLoading"
    borderRadius="10px"
    height="30rem"
    class="skeleton-dark p-3"
  />
  <div v-else>
    <div class="d-flex flex-column flex-sm-row justify-content-between">
      <h1 class="text-center text-primary-1 fw-bold fs-3">Meet the rest of the team</h1>
      <form
        class="d-flex justify-content-end align-items-center gap-1"
        @submit.prevent="showFieldSearch"
      >
        <input-text
          v-if="isShowFieldSearch"
          placeholder="Search by name"
          class="rounded-0"
          v-model="search"
        />
        <i
          class="
            py-2
            px-3
            bg-primary-1
            text-white
            fs-5
            icon-search
            pointer
            border-r-10
            overflow-hidden
          "
          :class="isSearch ? 'pi pi-refresh' : 'pi pi-search'"
          @click="
            () => {
              !isSearch ? showFieldSearch() : resetSearch();
            }
          "
        ></i>
      </form>
    </div>
    <div class="teachers-pagination mt-4 px-5 px-sm-0" v-if="!isChanging">
      <template v-if="!isSearch">
        <template v-for="n in limitTeachers" :key="n">
          <card-small-info-component
            :total="2"
            v-if="
              countTeachers <= n &&
              (actualPage === 0 ? n <= limitTeachers : n < limitTeachers)
            "
            :key="n"
            :name="teachers.data[actualPage === 0 ? n - 1 : n]?.name"
            :photo="teachers.data[actualPage === 0 ? n - 1 : n]?.photo"
            :lastName="
              teachers.data[actualPage === 0 ? n - 1 : n]?.last_name
                ? teachers.data[actualPage === 0 ? n - 1 : n]?.last_name
                : 'lastName'
            "
            :cardTypes="['view-profile', 'w-last-name']"
            :goViewProfile="
              () =>
                toggleInfoTeacherModal(
                  true,
                  teachers.data[actualPage === 0 ? n - 1 : n]
                )
            "
          />
        </template>
      </template>
      <template v-else>
        <template v-for="n in limitTeachers" :key="n">
          <card-small-info-component
            v-if="
              countTeachers <= n &&
              (actualPage === 0 ? n <= limitTeachers : n < limitTeachers)
            "
            :key="n"
            :name="dataSearch[actualPage === 0 ? n - 1 : n]?.name"
            :photo="dataSearch[actualPage === 0 ? n - 1 : n]?.photo"
            :lastName="
              dataSearch[actualPage === 0 ? n - 1 : n]?.last_name
                ? dataSearch[actualPage === 0 ? n - 1 : n]?.last_name
                : 'lastName'
            "
            :cardTypes="['view-profile', 'w-last-name']"
            :goViewProfile="
              () =>
                toggleInfoTeacherModal(
                  true,
                  dataSearch[actualPage === 0 ? n - 1 : n]
                )
            "
          />
        </template>
      </template>
    </div>
    <div class="my-4">
      <Paginator
        v-if="teachers.data.length >= limitTeachers"
        :rows="12"
        @page="onPage"
        :totalRecords="totalRecords"
        :pageLinkSize="totalPage"
        v-model:first="first"
      ></Paginator>
    </div>
  </div>
</template>

<script>
import useMyTeachers from "@/modules/students/composables/MyTeachers/useMyTeachers";
import CardSmallInfoComponent from "@/shared/components/Cards/CardSmallInfoComponent.vue";
export default {
  name: "PaginationComponent",
  components: {
    CardSmallInfoComponent,
  },
  setup() {
    const {
      toggleInfoTeacherModal,
      teachers,
      countTeachers,
      onPage,
      isChanging,
      first,
      limitTeachers,
      isShowFieldSearch,
      showFieldSearch,
      search,
      totalPage,
      isSearch,
      resetSearch,
      dataSearch,
      totalRecords,
      actualPage,
    } = useMyTeachers();

    return {
      dataSearch,
      resetSearch,
      isSearch,
      limitTeachers,
      totalRecords,
      showFieldSearch,
      search,
      isShowFieldSearch,
      first,
      isChanging,
      onPage,
      toggleInfoTeacherModal,
      teachers,
      totalPage,
      actualPage,
      countTeachers,
    };
  },
};
</script>

<style lang="scss" scoped>
.teachers-pagination {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.p-paginator :first-child {
  margin-left: 0% !important;
}
.p-paginator {
  background-color: transparent !important;
}
</style>
