<template>
  <div class="bg-primary-5 border-r-10 p-4 d-flex flex-column justify-content-center">
    <h1 class="text-primary-0 fw-bold fs-3">Pending feedback</h1>
    <div class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center gap-6 mt-3">
      <p class="py-2 text-neutro-1">Check your class record and provide any pending feedback to your teachers.</p>
      <!--  -->
      <router-link :to="{ name: 'student-teachers-feedback' }">
        <button
          class="
            w-100
            w-sm-auto
            btn
            text-secondary-1
            border-secondary-1
            bg-hover-light-secondary-1
            p-2
            px-4
          "
        >
          View teachers
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerRememberComponent",
};
</script>

<style>
</style>