import { computed, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import useSharedUI from "../../../../shared/composables/useSharedUI";
import {
  hasMoreTwoClasses,
} from "../../../../shared/helpers/getFollowedClasses";
const useFeedback = () => {
  const store = useStore();
  const isLoadingSend = ref(false);
  const countFeedbacks = ref(5);
  const getQuestions = ref(false);
  const { sendNotificationFeedbackTeacher } = useSharedUI();
  const answers = ref({
    answers: {},
  });

  const questions = ref({});

  const loadServices = async () => {
    await store.dispatch("students/dashboardStart");
    await store.dispatch("students/feedbacks");
    await store.dispatch("students/lastClass");
  };

  const lastTeacherModal = computed(
    () => store.getters["students/studentLastTeacherModal"]
  );

  const toggleLastTeacherModal = (isOpen, data = {}, mood) => {
    store.commit("students/studentToggleLastTeacherModal", { isOpen, mood });
    if (isOpen) store.commit("students/feedback", { data: data });
  };

  const clearSubmit = () => {
    answers.value.answers = [];
  };

  const showMore = () => {
    countFeedbacks.value = countFeedbacks.value + 5;
  };

  const sendFeedbackNew = async (new_answers) => {
    let res = await store.dispatch("students/sendFeedback", {
      payload: new_answers,
    });
    if (res) {
      store.commit("shared/feedbackOrNoteConfirm", {
        data: { answers: new_answers },
        isOpenConfirm: false,
        isLoading: false,
      });
      sendNotificationFeedbackTeacher();
      clearSubmit();
      loadServices();
      const questions_data = store.getters["students/questions"];
      setAnswers(questions_data.data);
    }
  };

  const sendFeedback = async () => {
    isLoadingSend.value = true;
    const feedback = store.getters["students/feedback"];
    const new_answers = [];
    const { data } = store.getters["shared/feedbackOrNoteConfirm"];
    if (Object.entries(data).length === 0) {
      Object.entries(answers.value.answers).forEach(([key, value]) => {
        if (Array.isArray(value))
          value.forEach((answer) => {
            answer.lesson_id = feedback.data?.lesson_id
              ? feedback.data.lesson_id
              : feedback.data.id;
            new_answers.push(answer);
          });
      });
    } else {
      store.commit("shared/feedbackOrNoteConfirm", {
        isLoading: true,
      });
      data.answers.forEach((answer) => {
        answer.lesson_id = feedback.data?.lesson_id
          ? feedback.data.lesson_id
          : feedback.data.id;
        new_answers.push(answer);
      });
    }
    if (hasMoreTwoClasses(feedback.data)) {
      const array_answers = []
      feedback.data.arraysIdLessons.forEach((id) => {
        const answers = [];
        new_answers.forEach((answer) => {
          const new_answer = { ...answer };
          new_answer.lesson_id = id;
          answers.push(new_answer);
        });
        array_answers.push(answers);
      });
      array_answers.forEach((answer) => {
        sendFeedbackNew(answer);
      })
    } else {
      sendFeedbackNew(new_answers);
    }

    store.dispatch("shared/resetNotificationStatus");
    isLoadingSend.value = false;
  };

  const feedback = computed(() => {
    return store.getters["students/feedback"];
  });

  const feedbacks = computed(() => {
    return store.getters["students/feedbacks"];
  });

  const questions_data = computed(() => {
    return store.getters["students/questions"];
  });

  const setAnswers = (questions_data) => {
    questions.value = _.groupBy(questions_data, (question) => question.title);
    Object.entries(questions.value).forEach(([key, value]) => {
      let answers_data = [];
      value.forEach((question, index) => {
        answers_data.push({
          question_detail_id: question.id,
          comment: "",
          lesson_id: null,
          stars: 0,
          topic: question.title,
        });
      });
      answers.value.answers[key] = answers_data;
    });
  };

  const getInfoFeedback = () => {
    return _.groupBy(
      feedback.value.data.feedback,
      (feedback) => feedback.title
    );
  };

  watchEffect(() => {
    const questions_data = store.getters["students/questions"];
    if (questions_data.data.length > 0 && getQuestions.value === false) {
      getQuestions.value = true;
      setAnswers(questions_data.data);
    }
  });

  return {
    getInfoFeedback,
    questions,
    questions_data,
    lastTeacherModal,
    toggleLastTeacherModal,
    sendFeedback,
    feedback,
    loadServices,
    feedbacks,
    answers,
    isLoadingSend,
    countFeedbacks,
    showMore,
  };
};

export default useFeedback;
