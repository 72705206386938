<template>
  <skeleton v-if="teachersFavorites.isLoading" borderRadius="10px" height="20rem" class="skeleton-dark p-3" />
  <div v-else class="bg-primary-5 border-r-10 p-4">
    <h1 class="text-primary-0 fw-bold fs-3">Your favorite teachers</h1>
    <div class="teachers-favorites mt-3 d-none d-md-grid">
      <card-small-info-component :total="2" :cardTypes="['w-book-class', 'w-bookx-class']"
        v-for="(value, index) in teachersFavorites.data.slice(0, 5)" :key="index" :id="value.teacher_id"
        :name="value.name" :photo="value.photo" :lastName="value.last_name ? value.last_name : 'lastName'"
        :goViewProfile="() => toggleInfoTeacherModal(true, value)" :goBookAClass="() => goToBook(true, value)" />

      <div v-for="(value, index) in newArrayEmpty" :key="index" class="
          bg-primary-6
          border-r-10
          d-flex
          text-center
          align-items-center
          p-4
        " style="height: 15rem;">
        <h1 class="text-primary-0 fw-bold fs-5">Add your {{ (index + teachersFavorites.data.length) + 1 }}{{sufix[index + teachersFavorites.data.length]}} favorite teacher</h1>
      </div>
    </div>
    <div class="mt-3 d-md-none">
      <Carousel :value="teachersFavorites.data.slice(0, 5)" :numVisible="1" :numScroll="1"
        contentClass="carousel-custom-2 px-2 mt-4 pb-3" indicatorsContentClass="d-none">
        <template #item="slotProps">
          <div class="px-3 my-4">
            <card-small-info-component :total="0" :cardTypes="['w-book-class', 'w-bookx-class']"
              :id="slotProps.data.teacher_id"
              :name="slotProps.data.name" :photo="slotProps.data.photo" :lastName="slotProps.data.last_name ? slotProps.data.last_name : 'lastName'"
              :goViewProfile="() => toggleInfoTeacherModal(true, slotProps.data)" :goBookAClass="() => goToBook(true, slotProps.data)" />

          </div>

        </template>
      </Carousel>
       <div class="
          bg-primary-6
          border-r-10
          d-flex
          text-center
          align-items-center
          p-5
        " style="height: 5rem;">
        <h1 class="text-primary-0 fw-bold fs-5">Add your {{ teachersFavorites.data.length + 1 }}{{sufix[teachersFavorites.data.length]}} favorite teacher</h1>
      </div>
    </div>
  </div>
</template>

<script>
import CardSmallInfoComponent from "@/shared/components/Cards/CardSmallInfoComponent.vue";
import useMyTeachers from "@/modules/students/composables/MyTeachers/useMyTeachers";
import useSharedUI from "../../../../shared/composables/useSharedUI";
import { computed, onUpdated } from "vue";
export default {
  name: "FavoritesComponent",
  components: {
    CardSmallInfoComponent,
  },
  setup() {
    const { teachersFavorites, toggleInfoTeacherModal } = useMyTeachers();
    const { goToBook } = useSharedUI()

    const sufix = ["st", "nd", "rd", "th", "th"]

    const newArrayEmpty = computed(() => {
      return new Array(5).slice(0, 5 - teachersFavorites.value.data.length)
    })

    return {
      teachersFavorites,
      newArrayEmpty,
      goToBook,
      toggleInfoTeacherModal,
      sufix
    }
  }
};
</script>

<style lang="scss" scoped>
.teachers-favorites {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
}
</style>