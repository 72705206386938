<template>
  <div class="my-teachers-grid d-flex flex-column d-md-grid">
    <banner-feedback-component />
    <banner-remember-component />
    <favorites-component />
    <pagination-component />
  </div>
</template>

<script>
import BannerFeedbackComponent from "@/modules/students/components/MyTeachers/BannerFeedbackComponent";
import BannerRememberComponent from "@/modules/students/components/MyTeachers/BannerRememberComponent";
import FavoritesComponent from "@/modules/students/components/MyTeachers/FavoritesComponent";
import PaginationComponent from "@/modules/students/components/MyTeachers/PaginationComponent";
import useMyTeachers from "@/modules/students/composables/MyTeachers/useMyTeachers";
export default {
  name: "MyTeachersView",
  components: {
    BannerFeedbackComponent,
    BannerRememberComponent,
    FavoritesComponent,
    PaginationComponent,
  },
  setup(){
    const { loadServices } = useMyTeachers();

    loadServices();
   
  }
};
</script>

<style lang="scss" scoped>
.my-teachers-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  & div:nth-child(3),
  & div:nth-child(4) {
    grid-column: 1 / 3;
  }
}
</style>