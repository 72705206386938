<template>
  <skeleton
    v-if="lastClass.isLoading"
    borderRadius="10px"
    height="12.5rem"
    class="skeleton-dark p-3"
  />
  <div v-else class="bg-primary-5 border-r-10 p-4">
    <h1 class="text-primary-0 fw-bold fs-3">
      Rate your latest teacher
    </h1>
    <div class="d-flex flex-row justify-content-between align-items-sm-center gap-3 mt-3">
      <p class="py-2 flex-grow-1 text-neutro-1">
        Provide feedback to your latest teacher
      </p>

      <button
        :disabled="!lastClass.data?.has_student_feedback && Object.entries(lastClass.data).length === 0"
        class="btn text-white bg-secondary-1 bg-hover-secondary-1 bl-shadow p-2"
        style="max-width:154px"
        @click="toggleLastTeacherModal(true, lastClass.data,'new')"
      >
        Provide feedback
      </button>
    </div>
  </div>
</template>

<script>
import useFeedback from "@/modules/students/composables/MyTeachers/useFeedback";
import useMyTeachers from "@/modules/students/composables/MyTeachers/useMyTeachers";

export default {
  name: "BannerFeedbackComponent",
  setup() {
    const { toggleLastTeacherModal } = useFeedback();
    const { lastClass } = useMyTeachers();
    return { toggleLastTeacherModal, lastClass };
  },
};
</script>

<style></style>
